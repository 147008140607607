<div
  class="placeholder-glow"
  [ngClass]="{ 'cardWithTabs card px-2 pt-3 pb-2 mb-0': showViewsHeader(), firstTabActive: isFirstViewTabSelected() }"
>
  @if (showViewsHeader()) {
    <ul ngbNav [activeId]="0" class="nav-tabs viewTabs" *ngIf="(!views() || !views()[currentViewId()]) && isFetchingRows()">
      <li [ngbNavItem]="0">
        <button ngbNavLink>
          <span class="placeholder" style="width: 3rem"></span>
        </button>
      </li>
    </ul>
    <ul ngbNav [activeId]="0" class="nav-tabs viewTabs" *ngIf="(!views() || !views()[currentViewId()]) && !isFetchingRows()">
      <li [ngbNavItem]="0">
        <button ngbNavLink>
          <span>No Columns Found</span>
        </button>
      </li>
    </ul>
    <ul ngbNav #viewNav="ngbNav" [activeId]="currentViewId()" class="nav-tabs viewTabs" *ngIf="views() && views()[currentViewId()]">
      <li *ngFor="let view of views(); let i = index" [ngbNavItem]="i">
        <ng-container *ngIf="view.isPinned">
          <button ngbNavLink (click)="viewTabClicked.emit(i)">
            <span>{{ view.label }}</span>
            <span *ngIf="view.unsavedChanges"> *</span>
          </button>
        </ng-container>
      </li>
      <li>
        <button ngbNavLink [ngbNavItem]="-1" (click)="openViewGalleryClicked.emit(currentViewId())">All Views</button>
      </li>
    </ul>
  }

  @if (showFiltersHeader()) {
    <div class="d-flex px-2" *ngIf="currentViewId() !== -1 && views() && views()[currentViewId()]">
      <app-data-view-filters
        [pageName]="pageName()"
        class="flex-grow-1 mb-2 mx-2"
        [(appliedFilters)]="views()[currentViewId()].appliedFilters"
        (filterChanged)="filterChanged.emit($event)"
        (filtersCleared)="filtersCleared.emit(true)"
        [(isLoadingOptions)]="isLoadingFilterOptions"
      />
      <div class="d-flex align-items-center gap-3">
        <chitin-checkbox
          *ngIf="showExcludeDemoNcpdpsToggle()"
          mode="switch"
          [value]="!excludeDemoNcpdps()"
          (valueChange)="excludeDemoNcpdps.set(!$event)"
          label="Include demo NCPDPs"
          style="margin-top: 0.3rem"
        />
        <ng-container *ngIf="views()[currentViewId()].unsavedChanges">
          <chitin-button (clicked)="saveViewClicked.emit()" size="small" label="Save view" />
          <chitin-button (clicked)="resetViewClicked.emit()" size="small" label="Reset view" color="secondary" />
        </ng-container>
        <chitin-button (clicked)="editColumnsClicked.emit()" size="small" color="secondary" label="Edit columns" />
      </div>
    </div>
  }
  <app-data-view-grid
    [hidden]="currentViewId() === -1"
    class="app-retail-data-grid desktop-scrollable"
    id="data-grid"
    [sortAble]="false"
    [hasFilters]="false"
    [isLoading]="isLoading()"
    [data]="data()"
    [isFetchingRows]="isFetchingRows()"
    [columns]="columns()"
    [loadingUI]="LoadingUI.Placeholder"
    [showLoadingInitOnly]="true"
    [templates]="{
      genericDynamic,
      created,
      status,
      primaryCopayAmount,
      bool,
      claimFlagsList,
      economicValue,
      detectionCount,
      indexScore,
      generatedByModel,
      brightBenchStatus,
    }"
    [activeRowId]="selectedRowId()"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
    (itemClicked)="onRowClicked($event)"
    [scrollWindow]="false"
    [resizableColumns]="true"
    (columnsEdited)="viewEdited.emit()"
  />

  <ng-template #genericDynamic let-item="item" let-column="column">
    <div class="d-flex flex-column">
      <div
        *ngFor="let displayField of column.appliedSettings?.displayFields; let i = index"
        class="overflow-ellipsis"
        [ngClass]="{ 'text-small': i !== 0 }"
        placement="bottom"
        [ngbTooltip]="item[displayField]"
        container="body"
      >
        {{ item[displayField] }}
      </div>
    </div>
  </ng-template>

  <ng-template #created let-item="item" let-column="column">
    <span *ngIf="column.appliedSettings?.displayFields.includes('date')"> {{ item.created | date: 'MM/dd/yyyy' }} </span>
    <span *ngIf="column.appliedSettings?.displayFields.includes('time')"> {{ item.created | date: 'H:mm:ss' }} </span>
  </ng-template>

  <ng-template #status let-item="item">
    <div class="badge statusBadge rounded-pill" [ngClass]="item.statusClass">{{ item.status }}</div>
  </ng-template>

  <ng-template #primaryCopayAmount let-item="item">
    <span>{{ item.primaryCopayAmount | currency }}</span>
  </ng-template>

  <ng-template #bool let-item="item" let-column="column">
    <span [ngClass]="{ 'font-weight-bold': !!item[column.field] }">{{ !!item[column.field] ? 'Yes' : 'No' }}</span>
  </ng-template>

  <ng-template #claimFlagsList let-item="item">
    <app-shared-claim-flags [claim]="item" />
  </ng-template>

  <ng-template #economicValue let-item="item">
    <span>{{ item.economicValue | currency }}</span>
  </ng-template>

  <ng-template #indexScore let-item="item">
    <span [ngbTooltip]="item.primaryFactor ? 'Primary Factor Contributing to Index: ' + item.primaryFactor : undefined" container="body">
      {{ item.indexScore }}
    </span>
  </ng-template>

  <ng-template #detectionCount let-item="item">
    <span> {{ item.detectionCount ?? 0 }} issue{{ (item.detectionCount ?? 0) === 1 ? '' : 's' }} detected </span>
  </ng-template>

  <ng-template #generatedByModel>
    <span>{{ modelAndVersionString() ?? '' }}</span>
  </ng-template>

  <ng-template #brightBenchStatus let-item="item">
    @if (item.brightBenchStatus) {
      <div class="d-flex gap-1 align-items-center" style="margin-top: -2px">
        <div class="profilePic">
          <img [src]="item.brightBenchProfilePic" />
        </div>
        <div>{{ item.brightBenchStatus }}</div>
      </div>
    }
  </ng-template>

  <app-infiniscroll-paginator
    #paginator
    [hidden]="!views() || !views()[currentViewId()] || currentViewId() === -1"
    [loading]="isLoading()"
    [pageSize]="30"
  />
</div>
