export const AGGREGATED_STATUS_PAID = 'paid';
export const AGGREGATED_STATUS_UNPAID = 'unpaid';
export const AGGREGATED_STATUS_CLOSED = 'closed';
export const AGGREGATED_STATUS_PENDING = 'pending';
export const AGGREGATED_STATUS_PAID_ADJUSTED = 'paidAdjusted';
export const AGGREGATED_STATUS_UNPAID_ADJUSTED = 'unpaidAdjusted';
export const AGGREGATED_STATUS_CLOSED_ADJUSTED = 'closedAdjusted';
export const AGGREGATED_STATUS_PENDING_ADJUSTED = 'pendingAdjusted';

const sharedPatientAssistancePharmacyPaymentStatementAggregatedStatusesAsConst = [
  AGGREGATED_STATUS_PAID,
  AGGREGATED_STATUS_UNPAID,
  AGGREGATED_STATUS_CLOSED,
  AGGREGATED_STATUS_PENDING,
  AGGREGATED_STATUS_PAID_ADJUSTED,
  AGGREGATED_STATUS_UNPAID_ADJUSTED,
  AGGREGATED_STATUS_CLOSED_ADJUSTED,
  AGGREGATED_STATUS_PENDING_ADJUSTED,
] as const;

export const SHARED_PATIENT_ASSISTANCE_PHARMACY_PAYMENT_STATEMENT_AGGREGATED_STATUSES = [
  ...sharedPatientAssistancePharmacyPaymentStatementAggregatedStatusesAsConst,
];

export type SharedPatientAssistancePharmacyPaymentStatementAggregatedStatusType =
  (typeof sharedPatientAssistancePharmacyPaymentStatementAggregatedStatusesAsConst)[number];
