<div *ngIf="viewMode === 'chips'" class="d-flex align-items-center">
  <div class="d-flex flex-1 flex-wrap gap-2" [ngClass]="{ 'me-2': appliedFilters.length > 0 }">
    <ng-container *ngIf="!isLoadingOptions">
      <div
        *ngFor="let filter of appliedFilters; index as i"
        class="badge filter-badge text-black bg-light d-flex align-items-center filterBadge"
        style="font-size: 0.85rem"
        [ngbPopover]="addFilterPopover"
        placement="bottom-left"
        #filterPopover="ngbPopover"
        (click)="openPopover(filter)"
        [autoClose]="false"
        popoverClass="filterPopover"
      >
        <span class="me-1" data-cy="pharmacyFilter">{{ filter.display }}</span>

        <chitin-icon class="me-1" style="font-size: 0.7rem" *ngIf="filter.icon" [icon]="filter.icon" />
        <span class="me-1 iconTextSmall" *ngIf="filter.iconText && !filter.iconTextPostfix">{{ filter.iconText }}</span>

        <span>{{ filter.displayValue ?? filter.value }}</span>

        <span *ngIf="filter.iconText && filter.iconTextPostfix">{{ filter.iconText }}</span>

        @if (!filter.required) {
          <a class="ms-1" data-cy="removeFilter" (click)="removeFilter(i)">
            <chitin-icon icon="fa-solid fa-xmark" />
          </a>
        }
        <ng-template #addFilterPopover>
          <div class="filterPopoverContainer">
            <app-data-view-add-filter
              *ngIf="activeFilter"
              [model]="activeFilter"
              [activePopover]="filterPopover"
              (filterChanged)="filterEdited($event)"
            />
          </div>
        </ng-template>
      </div>
    </ng-container>
    <div *ngIf="isLoadingOptions" class="spinner-border spinner-border-sm text-dark m-2 me-3" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="d-flex flex-shrink-0 gap-2">
    <chitin-button
      [disabled]="isLoadingOptions"
      (clicked)="addFilter()"
      size="small"
      color="secondary"
      icon="fa-solid fa-plus"
      label="Add filter"
    />
    <chitin-button
      [disabled]="isLoadingOptions"
      *ngIf="showClearAll"
      (clicked)="clearAllClicked()"
      size="small"
      color="secondary"
      label="Clear all"
      data-cy="clearBtn"
    />
  </div>
</div>

<div *ngIf="viewMode === 'rows'">
  <div *ngIf="!isLoadingOptions">
    <div *ngFor="let filter of appliedFilters; index as i" class="d-flex align-items-center gap-2 mb-2">
      <div>{{ filter.display }}</div>
      <app-data-view-add-filter style="flex-grow: 1" [model]="filter" (filterChanged)="filterEdited($event)" viewMode="row" />
      <a (click)="removeFilter(i)">
        <chitin-icon icon="fa-solid fa-xmark" />
      </a>
    </div>
  </div>
  <div *ngIf="isLoadingOptions" class="d-flex justify-content-center">
    <div class="spinner-border text-dark mt-2 mb-4" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="d-flex gap-2">
    <chitin-button
      [disabled]="isLoadingOptions"
      (clicked)="addFilter()"
      size="small"
      color="secondary"
      icon="fa-solid fa-plus"
      label="Add filter"
    />
    <chitin-button
      [disabled]="isLoadingOptions"
      *ngIf="appliedFilters.length > 0"
      (clicked)="clearAllClicked()"
      size="small"
      color="secondary"
      label="Clear all"
    />
  </div>
</div>
