<div class="vh-100 overflow-auto">
  <div class="offcanvas-header" data-cy="viewsGallery">
    <h3 id="offcanvasRightLabel">Views gallery</h3>
    <button
      (click)="activeOffcanvas.dismiss()"
      type="button"
      class="btn-close text-reset"
      data-cy="btnCloseViews"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    @if (pinnedViews().length > 0) {
      <h4>Pinned</h4>
      <div class="card viewContainer" cdkDropList (cdkDropListDropped)="viewDropped($event)">
        @for (view of pinnedViews(); track view.id) {
          <div cdkDrag class="view draggable">
            <div class="d-flex align-items-center">
              <chitin-icon class="pe-3" icon="fa-solid fa-bars" />
              <h5 class="mb-0">{{ view.label }}</h5>
            </div>
            <div class="d-flex gap-3 align-items-center">
              @if (view.isShareable) {
                <chitin-checkbox
                  class="mt-1"
                  [value]="!view.isCustom"
                  (valueChange)="viewToggleShared(view)"
                  label="Share view"
                  mode="switch"
                />
              }
              @if (canUnpinViews()) {
                <chitin-button (clicked)="viewTogglePinned(view.id)" icon="fa-solid fa-thumbtack" label="" iconPosition="center" />
              }
            </div>
          </div>
        }
      </div>
    }
    <h4>Views shared with me</h4>
    <div class="card viewContainer">
      @for (view of defaultViews(); track view.id) {
        <div class="view">
          <div class="d-flex gap-2 align-items-end">
            <h5 class="mb-0">{{ view.label }}</h5>
            @if (view.ownerUsername) {
              <div class="sharedBy">Shared by {{ view.ownerUsername }}</div>
            } @else {
              <div class="sharedBy">Shared by admin</div>
            }
          </div>
          <div class="d-flex gap-1">
            @if (!view.isPinned || canUnpinViews()) {
              <chitin-button
                (clicked)="viewTogglePinned(view.id)"
                [icon]="(view.isPinned ? 'fa-solid' : 'fa-light') + ' fa-thumbtack'"
                label=""
                iconPosition="center"
              />
            }
          </div>
        </div>
      }
    </div>
    @if (customViews().length > 0) {
      <h4>My Views</h4>
      <div class="card viewContainer">
        @for (view of customViews(); track view.id; let i = $index) {
          <div class="view">
            <h5 class="mb-0">{{ view.label }}</h5>
            <div class="d-flex gap-3 align-items-center">
              @if (isSuperAdmin()) {
                <chitin-checkbox
                  class="mt-1"
                  [value]="!view.isCustom"
                  (valueChange)="viewToggleShared(view)"
                  label="Share view"
                  mode="switch"
                />
              }
              @if (!view.isPinned || canUnpinViews()) {
                <chitin-button
                  (clicked)="viewTogglePinned(view.id)"
                  [icon]="(view.isPinned ? 'fa-solid' : 'fa-light') + ' fa-thumbtack'"
                  label=""
                  iconPosition="center"
                />
              }
              <chitin-button (clicked)="viewDeleted(view)" data-cy="deleteView" icon="fa-solid fa-xmark" label="" iconPosition="center" />
            </div>
          </div>
        }
      </div>
    }
  </div>
</div>
