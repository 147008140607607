import { FormlyFieldConfig } from '@ngx-formly/core';

export class AccountInfoForm {
  static create(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'row mb-2',
        fieldGroup: [
          {
            className: 'col-6',
            key: 'first_name',
            type: 'input',
            props: { label: 'First Name', required: true, cols: 6 },
          },
          {
            className: 'col-6',
            key: 'last_name',
            type: 'input',
            props: { label: 'Last Name', required: true, cols: 6 },
          },
        ],
      },
      {
        className: 'row mb-2',
        key: 'email',
        type: 'input',
        props: { label: 'Email', type: 'email', required: true },
        modelOptions: {
          updateOn: 'blur',
        },
      },
    ];
  }
}
