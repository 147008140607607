import { inject, Injectable } from '@angular/core';
import { OpenSearchRequest, OpenSearchRequestFilter } from '@libs/shared/domain/aggregations/opensearch/open-search-query.schema';
import { ROLE_FIELD_ADMIN, ROLE_PROGRAM_ADMIN } from '@libs/shared/domain/master-data/user/shared-master-data-user-role.type';
import { PROJECTION_INDEXES } from '@libs/shared/infrastructure/projection_indexes';
import { AuthService } from '@services/auth.service';
import { PharmacyViewModel } from '@services/models/pharmacy-view.model';
import { OpenSearchDomainService } from '@shared/models/open-search-domain-service';
import { OpenSearchPage, OpenSearchQuerySimpleResult, OpenSearchSimpleSort } from '@shared/providers/opensearch-api';
import { OpenSearchService } from '@shared/providers/opensearch.service';
import { lastValueFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PharmaciesSearchService extends OpenSearchService implements OpenSearchDomainService<PharmacyViewModel> {
  readonly indexName = PROJECTION_INDEXES.PHARMACY_GENERAL_VIEW;
  readonly dataviewColumns = 'pharmacy_general_columns';
  private authService = inject(AuthService);

  get(ncpdp: string): Observable<PharmacyViewModel | undefined> {
    const filter: OpenSearchRequestFilter[] = [
      {
        field: 'ncpdp',
        operator: 'equal',
        value: ncpdp,
      },
    ];
    this.updateFilterByRole(filter);
    const query: OpenSearchRequest = { filter, page: { size: 1 } };
    return this.client.search<PharmacyViewModel>(this.indexName, query).pipe(map(result => result.items[0]));
  }

  countData(filter: OpenSearchRequestFilter[]) {
    return this.client.count(this.indexName, { filter });
  }

  searchData(
    filter: OpenSearchRequestFilter[],
    page: OpenSearchPage,
    sort: OpenSearchSimpleSort,
    fields?: string[],
  ): Observable<OpenSearchQuerySimpleResult<PharmacyViewModel>> {
    const query: OpenSearchRequest = {
      filter,
      page,
      sort: this.createSearchSort(sort),
      select: fields,
    };
    return this.client
      .search<PharmacyViewModel>(this.indexName, query)
      .pipe(catchError(error => this.processSimpleSearchError<PharmacyViewModel>(error)));
  }

  public exportData(
    filter: OpenSearchRequestFilter[],
    sort: OpenSearchSimpleSort,
    fields?: string[],
  ): Observable<{
    fileUrl: string;
  }> {
    return this.client.export(
      this.indexName,
      {
        filter,
        sort: this.createSearchSort(sort),
        select: fields,
      },
      {
        dataview: this.dataviewColumns,
      },
    );
  }

  public async validateUniqueNpi(npi: string): Promise<boolean> {
    const npiFilter: OpenSearchRequestFilter = { field: 'npi', operator: 'equal', value: npi };
    const find = await lastValueFrom(this.searchData([npiFilter], { size: 1, from: 0 }, {}));
    return find.total === 0;
  }

  getPharmaciesByProgram(programGroup: string, tenantId: string): Observable<OpenSearchQuerySimpleResult<PharmacyViewModel>> {
    const filter: OpenSearchRequestFilter[] = [
      {
        field: 'enrollments',
        value: 'in',
        operator: 'in',
        nested: [
          {
            field: 'programGroup',
            operator: 'equal',
            value: programGroup,
          },
          {
            field: 'tenantId',
            operator: 'equal',
            value: tenantId,
          },
        ],
      },
    ];
    return this.searchData(filter, { from: 0, size: 9999 }, { name: 'asc' });
  }

  getPharmaciesHubsForNetwork(items: PharmacyViewModel[], programGroup: string, tenantId: string, network: string) {
    const hubs: string[] = [];

    items.forEach(pharmacy => {
      const enrollment = pharmacy.enrollments.find(item => item.programGroup === programGroup && item.tenantId === tenantId);
      if (enrollment?.networkGroup === network && enrollment?.isConsignmentHub) hubs.push(pharmacy.npi ?? 'Unknown');
    });

    return hubs;
  }

  private async updateFilterByRole(filter: OpenSearchRequestFilter[]) {
    const user = this.authService.getCurrentUser();
    const userTenant = user.tenants?.[0] ?? 'NONE';

    if ([ROLE_PROGRAM_ADMIN].includes(user.role)) {
      filter.push({
        field: 'enrollments',
        operator: 'in',
        value: [],
        nested: [
          {
            field: 'enrollments.tenantId',
            operator: 'in',
            value: user.tenants,
          },
        ],
      });
    }

    if (user.role === ROLE_FIELD_ADMIN) {
      filter.push({
        field: 'enrollments',
        operator: 'in',
        value: [],
        nested: [
          {
            field: 'enrollments.tenantId',
            operator: 'in',
            value: user.tenants,
          },
          {
            field: 'enrollments.programGroup',
            operator: 'in',
            value: ['INCY0'],
          },
          {
            field: 'enrollments.businessRegionId',
            operator: 'in',
            value: (user.business_regions ?? {})[userTenant] ?? [],
          },
        ],
      });
    }
  }
}
