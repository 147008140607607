import { computed, inject, Injectable, signal } from '@angular/core';
import config from '@config';
import { GrowthBook, LoadFeaturesOptions } from '@growthbook/growthbook';
import { AuthService } from '@services/auth.service';
import { TenantService } from '@services/tenant.service';
import { UserService } from '@services/user.service';
import { FeatureFlagContextAttributes } from '@shared/models/feature-flag-context-attributes';
import { IUser } from '@shared/models/user.model';
import { cloneDeep } from 'lodash';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  readonly authService = inject(AuthService);
  readonly userService = inject(UserService);
  readonly tenantSerivce = inject(TenantService);
  identity = signal<IUser | null>(null);
  gb = signal<GrowthBook>(this.createGb());
  flags = signal(this.gb().getFeatures());

  public constructor() {
    this.authService.authStatusChanged.subscribe(() => this.setUser());
    this.userService.userChanged.subscribe(() => this.setUser());
    this.tenantSerivce.activeTenantChanged.subscribe(() => this.setTenant());
    this.setUser();
    this.setTenant();
  }

  createGb() {
    return new GrowthBook({
      clientKey: config.growthbook.clientKey,
      enableDevMode: config.growthbook.enabledDevMode,
      attributes: {
        version: config.version,
      },
    });
  }

  async load(options?: LoadFeaturesOptions) {
    await this.gb().loadFeatures(options);
    this.flags.set(this.gb().getFeatures());
  }

  isOnWithContext(key: string, context: FeatureFlagContextAttributes): boolean {
    const contextGb: GrowthBook = cloneDeep(this.gb());
    contextGb.setAttributes({
      ...contextGb.getAttributes(),
      ...this.mapContextAttributes(context),
    });

    return contextGb.isOn(key);
  }

  isOn(key: string): boolean {
    return this.gb().isOn(key);
  }

  select(key: string) {
    return computed(() => this.flags() && this.gb().isOn(key));
  }

  getJsonValue(key: string, fallback?: Record<string, unknown>): Record<string, unknown> | undefined {
    return this.gb().getFeatureValue<Record<string, unknown> | undefined>(key, fallback);
  }

  getStringValue(key: string, fallback?: string): string | undefined {
    return this.gb().getFeatureValue<string | undefined>(key, fallback);
  }

  exists(key: string): boolean {
    return !!this.gb().getFeatures()[key];
  }

  getKeyForUrl(url: string): string {
    return `path:${url.replaceAll('/', '|')}`;
  }

  getUrlFromKey(key: string): string {
    return key.replaceAll('|', '/').substring('path:'.length);
  }

  protected setUser(): void {
    const user = this.authService.isUserAuthenticated() ? this.userService.getCurrentUser() : null;
    this.identity.set(user);
    if (user) {
      const attributes = this.gb().getAttributes();
      this.gb().setAttributes({
        ...attributes,
        id: user.username,
        role: user.role,
        ncpdps: user.ncpdps,
        tenantIds: this.tenantSerivce.usersTenants?.map(tenant => tenant.id),
        currentTenantId: this.tenantSerivce.currentTenant?.id,
      });
    } else {
      this.identity.set(user);
    }
  }

  protected setTenant(): void {
    if (this.identity()) {
      const attributes = this.gb().getAttributes();
      this.gb().setAttributes({
        ...attributes,
        tenantIds: this.tenantSerivce.usersTenants?.map(t => t.id),
        currentTenantId: this.tenantSerivce.currentTenant?.id,
      });
    }
  }

  async reload() {
    await this.load({ skipCache: true });
  }

  private mapContextAttributes(context: FeatureFlagContextAttributes): Record<string, any> {
    const attributes: Record<string, any> = {};
    Object.keys(context).forEach((key: string) => {
      attributes[`context${key.charAt(0).toUpperCase() + key.slice(1)}`] = context[key as keyof FeatureFlagContextAttributes];
    });
    attributes.version = config.version;

    return attributes;
  }
}
