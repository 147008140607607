import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, TemplateRef, inject, input, signal } from '@angular/core';
import {
  DataGridCheckedChanged,
  DataGridFilterChanged,
  DataGridItemClicked,
  DataGridSortChanged,
} from '@shared/components/data-grid/data-grid-events';
import { GridColumn } from '@shared/models/grid-column.model';
import { NgChanges } from '@shared/ng-changes';
import moment from 'moment';

export enum LoadingUI {
  Spinner,
  Placeholder,
  None,
}

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styles: ['.sortable:hover {cursor: pointer; color: black}'],
  styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent<T extends Record<string, any>> implements OnChanges {
  @Input() data: T[] = [];
  @Input() isLoading = false;
  @Input() columns: GridColumn[] = [];
  @Input() templates: Record<string, TemplateRef<any>> = {};
  @Input() checkAble = false;
  @Input() sortAble = false;
  @Input() hasFilters = true;
  @Input() sortOrder: 'asc' | 'desc' = 'asc';
  @Input() sortField = '';
  @Input() loadingUI = LoadingUI.Spinner;
  @Input() loadingRowsCount: number = 20;
  @Input() showLoadingInitOnly = false;
  @Input() isItemActive: (event: T) => boolean = () => false;
  @Input() smallFilters = false;
  @Input() showFilters = false;
  @Output() itemClicked = new EventEmitter<DataGridItemClicked<T>>();
  @Output() filterChanged = new EventEmitter<DataGridFilterChanged>();
  @Output() sortChanged = new EventEmitter<DataGridSortChanged>();
  @Output() checkedChanged = new EventEmitter<DataGridCheckedChanged<T>>();

  protected forceDesktopLayout = input<boolean>(false);

  readonly LoadingUI = LoadingUI;
  checkAll = false;
  checkedRows: Array<{ checked: boolean }> = [];
  filtersCount = 0;
  gridHeaderColumns: GridColumn[][] = [];
  gridBodyColumns: GridColumn[] = [];

  protected elementWithPriorFocus = signal<Element | null>(null);
  protected elementRef = inject(ElementRef);

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.data) {
      this.checkAll = false;
      this.onCheckAll(false);
    }
    if (changes.columns) {
      this.prepareColumns();
      this.filtersCount = this.gridBodyColumns.reduce((count, item) => {
        count += item.filter ? 1 : 0;
        return count;
      }, 0);
    }
    if (changes.isLoading) {
      if (changes.isLoading.currentValue === true) {
        if (this.elementRef.nativeElement.contains(document.activeElement)) {
          this.elementWithPriorFocus.set(document.activeElement);
        }
      } else {
        const elementWithPriorFocus = this.elementWithPriorFocus();
        if (elementWithPriorFocus !== null) {
          setTimeout(() => {
            (elementWithPriorFocus as HTMLElement).focus();
            this.elementWithPriorFocus.set(null);
          });
        }
      }
    }
  }

  onCheckAll(value: boolean) {
    this.checkedRows = this.data.map(() => {
      return { checked: value };
    });
    this.checkedChanged.next(this.getCheckedItems());
  }

  onCheckItem(value: boolean, index: number) {
    this.checkedRows[index] = { checked: value };
    this.checkedChanged.next(this.getCheckedItems());
  }

  onFilterChanged(value: any, column: GridColumn) {
    if (value) {
      if (['date', 'datetime'].includes(column.type ?? '')) {
        if (column.filterType !== 'daterange') {
          this.filterChanged.next({ value: moment(value).format('YYYY-MM-DD'), column });
          return;
        }
      }
      if (column.type === 'number') {
        this.filterChanged.next({ value: Number(value), column });
        return;
      }
    }
    this.filterChanged.next({ value, column });
  }

  onHeaderClick(column: GridColumn) {
    if (column.sort === true && this.sortAble) {
      this.setSort(column);
      this.sortChanged.emit({ column, field: this.sortField, order: this.sortOrder });
    }
  }

  onItemClick(item: T, column: GridColumn) {
    this.itemClicked.next({ item, column });
  }

  getCheckedItems(): T[] {
    const items: T[] = [];

    this.data.forEach((item, index) => {
      if (this.checkedRows[index].checked) {
        items.push(item);
      }
    });

    return items;
  }

  isClearable(column: GridColumn) {
    for (const option of column.filterOptions ?? []) {
      if (option.value === '') return false;
    }
    return true;
  }

  private setSort(column: GridColumn) {
    if (column.field !== this.sortField) {
      this.sortField = column.field;
      this.sortOrder = 'asc';
    } else if (this.sortOrder === 'asc') {
      this.sortOrder = 'desc';
    } else {
      this.sortOrder = 'asc';
    }
  }

  private prepareColumns() {
    this.gridHeaderColumns = [];
    this.gridBodyColumns = [];
    const multipleHeaderRows = this.columns.filter((col: GridColumn) => col.columns?.length);
    if (multipleHeaderRows.length) {
      this.gridHeaderColumns[0] = [];
      this.gridHeaderColumns[1] = [];
      for (const column of this.columns) {
        if (column.columns?.length) {
          column.headClasses = { ...column.headClasses, 'multi-header': true };
          column.rowspan = 1;
          column.colspan = column.columns.length;
          for (const col of column.columns) {
            this.gridHeaderColumns[1].push(col);
            this.gridBodyColumns.push(col);
          }
        } else {
          column.rowspan = 2;
          column.colspan = 1;
          this.gridBodyColumns.push(column);
        }

        this.gridHeaderColumns[0].push(column);
      }
    } else {
      this.gridHeaderColumns[0] = this.columns;
      this.gridBodyColumns = this.columns;
    }
  }
}
