import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, computed, signal } from '@angular/core';
import { ConfirmationDialogComponent } from '@app/business-rules/components/confirmation-dialog/confirmation-dialog.component';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '@services/dialog.service';
import { UserService } from '@services/user.service';
import { BaseComponent } from '@shared/components/base-component';
import { View } from '@shared/components/data-view/data-view-types';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-data-view-gallery',
  templateUrl: './data-view-gallery.component.html',
  styleUrls: ['./data-view-gallery.component.scss'],
})
export class DataViewGalleryComponent extends BaseComponent {
  protected currentUsername = signal<string>('');
  protected isSuperAdmin = signal<boolean>(false);

  views = signal<Array<View.UserView | View.DefaultView>>([]);

  protected pinnedViews = computed<Array<View.UserView & { isShareable?: boolean }>>(() =>
    this.views()
      .filter(v => v.isPinned)
      .map(pinnedView => ({
        ...pinnedView,
        isShareable:
          this.isSuperAdmin() &&
          (pinnedView.isCustom || ('ownerUsername' in pinnedView && pinnedView.ownerUsername === this.currentUsername())),
      })),
  );
  protected canUnpinViews = computed<boolean>(() => this.pinnedViews().length > 1);
  protected defaultViews = computed<View.DefaultView[]>(() =>
    this.views().filter(v => !v.isCustom && (!('ownerUsername' in v) || v.ownerUsername !== this.currentUsername())),
  );
  protected customViews = computed<View.UserView[]>(() =>
    this.views().filter(v => v.isCustom || ('ownerUsername' in v && v.ownerUsername === this.currentUsername())),
  );

  constructor(
    protected activeOffcanvas: NgbActiveOffcanvas,
    protected userService: UserService,
    private dialogService: DialogService,
  ) {
    super();
    this.currentUsername.set(userService.getCurrentUser().username);
    this.isSuperAdmin.set(userService.isSuperAdmin());
  }

  viewTogglePinned(viewId: string) {
    this.views.update(views =>
      views.map(view => ({
        ...view,
        isPinned: view.id === viewId ? !view.isPinned : view.isPinned,
      })),
    );
  }

  viewToggleShared(view: View.UserView | View.DefaultView) {
    if ('isCustom' in view && view.isCustom) {
      view.isCustom = false;
      (view as View.DefaultView).ownerUsername = this.userService.getCurrentUser().username;
    } else {
      (view as View.UserView).isCustom = true;
      if ('ownerUsername' in view) {
        delete view.ownerUsername;
      }
    }
  }

  viewDropped(event: CdkDragDrop<string[]>) {
    const movedViews: Array<View.UserView | View.DefaultView> = cloneDeep(this.views());

    const previousIndex = this.views().findIndex(view => view.id === this.pinnedViews()[event.previousIndex].id);
    const currentIndex = this.views().findIndex(view => view.id === this.pinnedViews()[event.currentIndex].id);
    moveItemInArray(movedViews, previousIndex, currentIndex);
    this.views.set(movedViews);
  }

  viewDeleted(view: View.UserView) {
    const confirmationModal = this.dialogService.openModal(ConfirmationDialogComponent, { centered: true });
    confirmationModal.componentInstance.question = `Are you sure you want to delete your ${view.label} view?`;
    this.subscribe(confirmationModal.closed, answer => {
      if (answer === true) {
        const cleanedViews = cloneDeep(this.views()).filter(v => v.id !== view.id);
        this.views.set(cleanedViews);
      }
    });
  }
}
