import { Component, EventEmitter, Input, OnChanges, Output, QueryList, ViewChildren, inject, input } from '@angular/core';
import config from '@config';
import { SHARED_PATIENT_ASSISTANCE_OCC_LIST } from '@libs/shared/domain/patient-assistance/occ/shared-patient-assistance-occ.type';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ClaimFlagsService } from '@services/claim-flags.service';
import { DialogService } from '@services/dialog.service';
import { LookupsService } from '@services/lookups.service';
import { PharmacyFamily } from '@services/models/pharmacy-family.model';
import { NetworkGroupApiService } from '@services/network-group-api.service';
import { PharmacyApiService } from '@services/pharmacy-api.service';
import { PharmacyPaymentStatementsService } from '@services/pharmacy-payment-statements.service';
import { ProductsService } from '@services/products.service';
import { ProgramGroupApiService } from '@services/program-group-api.service';
import { StatesService } from '@services/states.service';
import { TenantService } from '@services/tenant.service';
import { UserService } from '@services/user.service';
import { BaseComponent } from '@shared/components/base-component';
import { Filter } from '@shared/components/data-view/data-view-types';
import { DataViewFilterGalleryComponent } from '@shared/components/data-view/filter-gallery/data-view-filter-gallery.component';
import { updateFilterWithDisplayValue } from '@shared/components/data-view/filter-helpers';
import { INetworkGroup } from '@shared/models/network-group-model';
import { PAYOR_PLAN_TYPES } from '@shared/models/payor-plan-types';
import { IProgramGroup } from '@shared/models/program-group.model';
import { REJECT_REASONS } from '@shared/models/reject-reasons';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { Tenant } from '@shared/models/tenant.model';
import { NgChanges } from '@shared/ng-changes';
import { chain, keyBy, remove, sortBy } from 'lodash';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-data-view-filters',
  templateUrl: './data-view-filters.component.html',
  styleUrls: ['./data-view-filters.component.scss'],
})
export class DataViewFiltersComponent extends BaseComponent implements OnChanges {
  @Input() appliedFilters: Filter.AppliedDataViewFilter[] = [];
  @Input() pageName: string;
  @Input() isLoadingOptions = false;
  @Input() viewMode: 'chips' | 'rows' = 'chips';
  @Output() isLoadingOptionsChange = new EventEmitter<boolean>();
  @Output() appliedFiltersChange = new EventEmitter<Filter.AppliedDataViewFilter[]>();
  @ViewChildren('filterPopover') filterPopovers: QueryList<NgbPopover>;
  activeFilter?: Filter.AppliedDataViewFilter;
  isOffcanvasOpen = false;
  filterOptionsLoaded = false;
  showClearAll = false;

  claimFlagFilterSubcategories = input<string[]>();

  @Output()
  filterChanged = new EventEmitter<Filter.AppliedDataViewFilter[]>();
  @Output()
  filtersCleared = new EventEmitter<void>();

  private paymentStatementsService = inject(PharmacyPaymentStatementsService);

  constructor(
    private dialogService: DialogService,
    private productsService: ProductsService,
    private tenantService: TenantService,
    private programGroupApiService: ProgramGroupApiService,
    private networkGroupApiService: NetworkGroupApiService,
    private pharmacyApiService: PharmacyApiService,
    private claimFlagsService: ClaimFlagsService,
    private lookupsService: LookupsService,
    private userService: UserService,
  ) {
    super();
  }

  ngOnChanges(changes: NgChanges<this>): void {
    if (changes.appliedFilters) {
      this.setFilters(this.appliedFilters);
    }
  }

  async setFilters(appliedFilters: Filter.AppliedDataViewFilter[]) {
    this.isLoadingOptionsChange.next(true);
    for (const filter of appliedFilters) {
      if ((filter.options ?? []).length === 0) {
        this.updateFilterIcons(filter);
        await this.getFilterOptions(filter);
      }
    }
    this.appliedFilters = appliedFilters;
    this.showClearAll = appliedFilters.filter(filter => !filter.required).length > 0;
    this.filterOptionsLoaded = true;
    this.isLoadingOptionsChange.next(false);
  }

  forcePopoversClosed() {
    for (const popover of this.filterPopovers) {
      popover.close();
    }
  }

  addFilter() {
    for (const popover of this.filterPopovers) {
      popover.close();
    }
    if (this.isOffcanvasOpen) return;
    const offcanvas = this.dialogService.openSlideout(DataViewFilterGalleryComponent, {
      position: 'end',
    });
    this.isOffcanvasOpen = true;
    const offcanvasInstance = offcanvas.componentInstance;
    offcanvasInstance.appliedFilters = this.appliedFilters;
    offcanvasInstance.pageName = this.pageName;
    this.subscribe(offcanvas.hidden, async () => {
      this.isOffcanvasOpen = false;
      const newFilters = offcanvasInstance.newlyAppliedFilters;
      const removeFilterIds = offcanvasInstance.newlyRemovedFilterIds;
      if ((!newFilters || newFilters.length === 0) && (!removeFilterIds || removeFilterIds.length === 0)) {
        return;
      }

      const removedFilters = remove(this.appliedFilters, appliedFilter => removeFilterIds.includes(appliedFilter.id));
      if (removedFilters.length > 0) {
        this.filterChanged.next(
          removedFilters.map(removedFilter => ({
            ...removedFilter,
            value: undefined,
          })),
        );
      }
      await this.setFilters([...this.appliedFilters, ...newFilters]);
      this.filterChanged.next(newFilters);
      this.appliedFiltersChange.next(this.appliedFilters);
      setTimeout(() => {
        if (newFilters && newFilters.length > 0) {
          this.openPopover(newFilters[newFilters.length - 1], this.filterPopovers.last);
        }
      }, 250); // Wait for the adding animation to finish
    });
  }

  filterEdited(newFilter: Filter.AppliedDataViewFilter) {
    if (!newFilter) return;
    const oldFilterIndex = this.appliedFilters.findIndex(f => f.field === newFilter.field);
    if (oldFilterIndex !== -1) {
      this.updateFilterIcons(newFilter);
      this.appliedFilters[oldFilterIndex].type = newFilter.type;
      this.appliedFilters[oldFilterIndex].value = newFilter.value;
      this.appliedFilters[oldFilterIndex].timezone = newFilter.timezone;
      this.appliedFilters[oldFilterIndex].icon = newFilter.icon;
      this.appliedFilters[oldFilterIndex].iconText = newFilter.iconText;
      this.appliedFilters[oldFilterIndex].iconTextPostfix = newFilter.iconTextPostfix;
      this.appliedFilters[oldFilterIndex].displayValue = newFilter.displayValue;
    }
    this.filterChanged.next([newFilter]);
    this.appliedFiltersChange.next(this.appliedFilters);
  }

  openPopover(filter: Filter.AppliedDataViewFilter, popover?: NgbPopover) {
    for (const popover of this.filterPopovers) {
      popover.close();
    }
    this.activeFilter = filter;
    if (popover) {
      popover.open();
    }
  }

  removeFilter(filterIndex: number) {
    const filterToRemove = this.appliedFilters[filterIndex];
    if (filterToRemove.required) {
      return;
    }
    this.appliedFilters = this.appliedFilters.filter((f, index) => index !== filterIndex);
    this.filterChanged.next([
      {
        ...filterToRemove,
        value: undefined,
      },
    ]);
    this.appliedFiltersChange.next(this.appliedFilters);
  }

  clearAllClicked() {
    this.appliedFilters = [];
    this.filtersCleared.next();
    if (this.viewMode === 'rows') {
      this.appliedFiltersChange.next(this.appliedFilters);
    }
  }

  updateFilterIcons(filter: Filter.AppliedDataViewFilter) {
    if (filter.value !== undefined && filter.type) {
      updateFilterWithDisplayValue(filter);
    } else {
      filter.icon = undefined;
      filter.iconText = undefined;
      filter.iconTextPostfix = undefined;
      filter.displayValue = undefined;
    }
  }

  private async getFilterOptions(filter: Filter.AppliedDataViewFilter) {
    const userAccessibleTenants: Tenant[] = this.userService.getUserAccessibleTenants();
    const userAccessibleTenantIds: TenantIdType[] = userAccessibleTenants.map((tenant: Tenant) => tenant.id);
    const groupOptionsByTenant = userAccessibleTenants.length > 1;
    const tenantsMap = keyBy(userAccessibleTenants, 'id');

    if (filter.id.endsWith('State')) {
      filter.options = StatesService.getStates().map(state => ({
        name: state.name ?? state.value,
        value: state.value,
      }));
    } else if (filter.id === 'occ') {
      filter.options = SHARED_PATIENT_ASSISTANCE_OCC_LIST.map(item => ({ name: item, value: item }));
    } else if (filter.id === 'payorPlanType') {
      filter.options = chain(PAYOR_PLAN_TYPES)
        .sortBy([item => item.toLowerCase()])
        .map(item => ({ name: item, value: item }))
        .value();
    } else if (filter.id === 'rejectId') {
      filter.options = chain(Object.entries(REJECT_REASONS))
        .sortBy(([key]) => key.toLowerCase())
        .map(([key, value]) => ({ name: key, value }))
        .value();
    } else if (filter.id === 'productNdc') {
      filter.options = [];
      const productsMap = await lastValueFrom(this.productsService.getAll());
      for (const [tenantId, tenantProducts] of Object.entries(productsMap)) {
        const products = this.productsService.mapSelectList(tenantProducts);
        const tenantName = tenantsMap[tenantId]?.name ?? tenantId;
        if (groupOptionsByTenant) products.forEach(product => (product.group = tenantName));
        filter.options.push(...products);
      }
    } else if (filter.id === 'tenantId') {
      filter.options = userAccessibleTenants.map(tenant => ({
        name: tenant.name,
        value: tenant.id,
      }));
    } else if (filter.id === 'claimStatus') {
      filter.options = [];
      for (const tenant of userAccessibleTenants) {
        const claimResultTypes = config.claimResultTypes[tenant.id];
        filter.options.push(
          ...claimResultTypes.map(item => ({
            name: item.toString(),
            value: `groupBy:${tenant.id}|${item}`,
            group: groupOptionsByTenant ? (tenant.name ?? 'Other') : undefined,
          })),
        );
      }
    } else if (filter.id === 'pharmacyFamily') {
      filter.options = [];
      const familyOptions = await lastValueFrom(this.lookupsService.getSettings<PharmacyFamily[]>('pharmacy', 'family'));
      if (familyOptions) {
        filter.options = familyOptions.map(familyOption => ({
          name: familyOption.name,
          value: familyOption.id,
        }));
      }
    } else if (filter.id === 'programGroupId' || filter.id === 'pharmacyProgramGroup') {
      const observable$ = this.programGroupApiService.getAllProgramGroupsForTenants(userAccessibleTenantIds);
      const programGroups: Record<string, IProgramGroup[]> = await lastValueFrom(observable$);

      filter.options = [];
      for (const [tenantId, tenantProgramGroups] of Object.entries(programGroups)) {
        for (const programGroup of tenantProgramGroups) {
          if (
            (this.userService.isProgramAdmin() && (programGroup.is_blocked === 0 || programGroup.id === '3')) ||
            !this.userService.isProgramAdmin()
          ) {
            filter.options.push({
              name: programGroup.name,
              value: `groupBy:${tenantId}|${programGroup.id}`,
              group: groupOptionsByTenant ? (this.tenantService.getTenantById(tenantId as TenantIdType)?.name ?? 'Other') : undefined,
            });
          }
        }
      }
    } else if (filter.id === 'networkGroup' || filter.id === 'pharmacyConsignmentNetwork') {
      const observable$ = this.networkGroupApiService.getAllNetworkGroupsForTenants(userAccessibleTenantIds);
      const networkGroups: Record<string, INetworkGroup[]> = await lastValueFrom(observable$);
      filter.options = [];
      for (const [tenantId, tenantNetworkGroups] of Object.entries(networkGroups)) {
        for (const networkGroup of tenantNetworkGroups) {
          filter.options.push({
            name: networkGroup.name,
            value: `groupBy:${tenantId}|${networkGroup.id}`,
            group: groupOptionsByTenant ? (this.tenantService.getTenantById(tenantId as TenantIdType)?.name ?? 'Other') : undefined,
          });
        }
      }
    } else if (filter.id === 'pharmacypbaEnrollmentSystemVendor') {
      filter.options = [];
      const options = await lastValueFrom(this.pharmacyApiService.getPharmacySystemVendors());
      filter.options = sortBy(options, 'systemVendor').map(option => ({
        name: option.name ? option.name : option.id,
        value: option.id,
      }));
    } else if (filter.id === 'claimFlagsList') {
      const flags = await lastValueFrom(this.claimFlagsService.getFlagsSettings('ALM', false));
      filter.options = flags.map(flag => ({
        value: flag.name,
        name: this.claimFlagsService.interpolateFlagSettingTitle(flag),
        group: flag.category ?? 'Uncategorized',
        badge: flag.is_for_patient ? 'Patient Level' : undefined,
        badgeClass: flag.is_for_patient ? 'bg-info' : undefined,
      }));
    } else if (filter.id === 'pharmacyPaymentStatementStatus') {
      filter.options = this.paymentStatementsService.createAggregatedStatusDropdownItems();
    } else if (filter.id === 'claimPlaybookFlagsSubcategories') {
      filter.options = this.claimFlagFilterSubcategories()?.map(subcategory => ({ name: subcategory, value: subcategory })) ?? [];
    }
    return filter;
  }
}
