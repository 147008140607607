import { Component, computed, inject, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { signalAsync } from '@app/signals';
import { ClaimFlagsService } from '@services/claim-flags.service';
import { PatientAssistanceRequestViewModel } from '@services/models/patient-assistance-request-view.model';
import { FlagsMap } from '@shared/components/claim-flags/claim-flags-types';
import { tap } from 'rxjs';

@Component({
  selector: 'app-shared-claim-flags-detail',
  templateUrl: './claim-flags-detail.component.html',
  styleUrl: './claim-flags-detail.component.scss',
})
export class ClaimFlagsDetailComponent {
  private readonly claimFlagsService = inject(ClaimFlagsService);

  claim = input.required<PatientAssistanceRequestViewModel>();
  flagsListFilterValues = input<string[]>();
  flagsMap = signal<FlagsMap>(this.claimFlagsService.getFlagsMap());
  flagsCategories = toSignal(this.claimFlagsService.getFlagsCategories(), { initialValue: [] });
  flagsMapFiltered = computed(() => this.getFlagsMapFiltered());
  scanId = signal('');
  flagsScanned = signal(0);
  flagsCount = computed(() => this.getFlagsCount());
  flagsIdentity = computed(() => this.claimFlagsService.getFlagsIdenity(this.flagsMapFiltered()));
  flagsValueDescription = signal('');
  economicValue = computed(() => this.getEconomicValue());

  constructor() {
    signalAsync(this.claim, claim => this.onClaimChange(claim)); // replace with resource after angular 19
  }

  private onClaimChange(claim: PatientAssistanceRequestViewModel) {
    this.scanId.set(new Date(claim.created).getTime().toString().slice(3, 10));
    return this.claimFlagsService.getFlagsSettings(claim.tenantId, false).pipe(
      tap(flags => {
        const playbookFlags = this.flagsListFilterValues()?.length;
        this.flagsMap.set(this.claimFlagsService.getFlagsMap(claim, flags));
        this.flagsScanned.set(playbookFlags || Object.keys(claim.flags ?? {}).length);
        this.flagsValueDescription.set(this.claimFlagsService.getFlagsValueDescription(claim, this.flagsMapFiltered()));
      }),
    );
  }

  private getFlagsCount() {
    return (
      this.claim().flagsList?.filter(flag => {
        const flagsListFilterValues = this.flagsListFilterValues();
        if (flagsListFilterValues && flagsListFilterValues.length > 0) {
          return flagsListFilterValues.includes(flag);
        }
        return flag;
      }).length ?? 0
    );
  }

  private getEconomicValue() {
    const flagsListFilterValues = this.flagsListFilterValues();
    if (flagsListFilterValues && flagsListFilterValues.length > 0) {
      const filteredFlagAmounts = Object.entries(this.claim().flagsAmounts).filter(([flagKey]) => flagsListFilterValues.includes(flagKey));
      return Math.max(0, ...filteredFlagAmounts.map(flagAmount => flagAmount[1]));
    }
    return this.claim().flagsAmountsValue;
  }

  private getFlagsMapFiltered() {
    const flagsListFilterValues = this.flagsListFilterValues();
    if (flagsListFilterValues && flagsListFilterValues.length > 0) {
      const flagsMap = this.flagsMap();
      for (const flagCategory of Object.keys(flagsMap)) {
        flagsMap[flagCategory as keyof typeof flagsMap] = flagsMap[flagCategory as keyof typeof flagsMap].filter(flag =>
          flagsListFilterValues.includes(flag.name),
        );
      }
      return flagsMap;
    }
    return this.flagsMap();
  }
}
