<button
  class="btn position-relative"
  (click)="onClick($event)"
  [routerLink]="routerLink"
  [ngClass]="classes"
  [disabled]="disabled || this.isLoading"
  [type]="type"
>
  <span *ngIf="isLoading" class="position-absolute loadingSpinner">
    <span class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </span>
  </span>
  <chitin-icon
    *ngIf="icon"
    [icon]="icon"
    class="btn-icon"
    [ngClass]="{ 'float-end ms-2': iconPosition === 'right', 'float-start me-2': iconPosition === 'left' }"
  ></chitin-icon>
  <span *ngIf="label" [ngClass]="{ 'opacity-0': this.isLoading }" class="btn-label"> {{ label }} </span>
  <ng-content [ngClass]="{ 'opacity-0': this.isLoading }"></ng-content>
</button>
