import { AfterViewInit, Component, DestroyRef, inject, input, model, OnInit, output, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { PatientAssistanceRequestViewModel } from '@services/models/patient-assistance-request-view.model';
import { UserService } from '@services/user.service';
import { LoadingUI } from '@shared/components/data-grid/data-grid.component';
import { InfiniscrollPaginatorComponent } from '@shared/components/data-table/infiniscroll-paginator/infiniscroll-paginator.component';
import { Column, Filter, View } from '@shared/components/data-view/data-view-types';
import { DataViewGridItemClicked } from '@shared/components/data-view/grid/data-view-grid-events';
import { DataviewOpenSearchFilterField } from '@shared/providers/dataview-opensearch-filter';
import { OpenSearchSimpleSort } from '@shared/providers/opensearch-api';

const INFINISCROLL_ENABLED = true;

@Component({
  selector: 'app-rx-dashboard-ccr-dataview',
  templateUrl: './rx-dashboard-ccr-dataview.component.html',
  styleUrls: ['./rx-dashboard-ccr-dataview.component.scss'],
})
export class RxDashboardCCRDataViewComponent implements OnInit, AfterViewInit {
  showFiltersHeader = input(true);
  showViewsHeader = input(true);

  pageName = input.required<string>();
  views = input.required<Array<View.UserView | View.DefaultView>>();
  currentViewId = input.required<number>();
  data = input.required<PatientAssistanceRequestViewModel[]>();
  columns = input.required<Column.AppliedDataViewColumn[]>();
  modelAndVersionString = input<string>();
  isFirstViewTabSelected = input.required<boolean>();

  excludeDemoNcpdps = model<boolean>();
  isLoading = model(false);
  isLoadingFilterOptions = model(false);
  isFetchingRows = model(true);
  selectedRowId = model<number>();

  showExcludeDemoNcpdpsToggle = signal(false);

  rowClicked = output<DataViewGridItemClicked<PatientAssistanceRequestViewModel>>();
  viewTabClicked = output<number>();
  openViewGalleryClicked = output<number>();
  saveViewClicked = output<void>();
  resetViewClicked = output<void>();
  editColumnsClicked = output<void>();
  viewEdited = output<void>();
  filterChanged = output<Filter.AppliedDataViewFilter[]>();
  filtersCleared = output<boolean>();
  paginatorLoaded = output<InfiniscrollPaginatorComponent>();
  viewNavLoaded = output<NgbNav>();

  private userService = inject(UserService);
  private destroyRef = inject(DestroyRef);

  public LoadingUI = LoadingUI;
  public implicitFilterFields: Record<string, DataviewOpenSearchFilterField> = {
    tenantId: { type: 'in' },
    pharmacyNcpdp: { type: 'nin' },
    flagsList: { type: 'in' },
  };
  public sort: OpenSearchSimpleSort = { created: 'desc', pharmacyNcpdp: 'asc', rxNumber: 'asc' };
  @ViewChild('paginator', { static: false }) paginator: InfiniscrollPaginatorComponent;
  @ViewChild('viewNav', { static: false }) viewNav: NgbNav;

  constructor() {
    toObservable(this.views)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(views => {
        if (views && views[this.currentViewId()]) {
          // Allow the UI to refresh and update view child property
          setTimeout(() => {
            this.viewNavLoaded.emit(this.viewNav);
          });
        }
      });
  }

  public ngOnInit() {
    this.showExcludeDemoNcpdpsToggle.set(this.userService.isSuperAdmin());
    this.excludeDemoNcpdps.set(true);
  }

  public ngAfterViewInit() {
    this.paginatorLoaded.emit(this.paginator);
    this.viewNavLoaded.emit(this.viewNav);
  }

  public onScroll(): void {
    if (!this.paginator.hasNextPage() || this.isLoading() || !INFINISCROLL_ENABLED) return;
    this.paginator.nextPage();
  }

  public onRowClicked(copayRequestEvent: DataViewGridItemClicked<PatientAssistanceRequestViewModel>) {
    this.selectedRowId.set(copayRequestEvent.rowId);
    this.rowClicked.emit(copayRequestEvent);
  }
}
