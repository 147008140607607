import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UrlTree } from '@angular/router';

@Component({
  selector: 'chitin-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ChitinButtonComponent {
  @Input()
  disabled: boolean = false;

  @Input()
  isLoading: boolean = false;

  @Input()
  outline: boolean = false;

  @Input()
  color: 'primary' | 'danger' | 'success' | 'secondary' | 'text' | 'lighter-blue' | 'pink' = 'primary';

  @Input()
  variant: 'text' | 'button' = 'button';

  @Input()
  size: 'xs' | 'small' | 'medium' | 'large' = 'medium';

  @Input()
  type: 'button' | 'submit' | 'reset' = 'button';

  /**
   * Button contents
   *
   * @required
   */
  @Input()
  label = 'Button';

  @Input()
  icon?: string;

  @Input()
  iconPosition: 'left' | 'right' | 'center' = 'left';

  @Input()
  fullwidth?: boolean = false;

  @Input()
  routerLink?: string[] | string | UrlTree | null;

  /**
   * Optional click handler
   */
  @Output()
  clicked = new EventEmitter<Event>();

  public onClick(event: MouseEvent): void {
    if (this.disabled || this.isLoading) return;
    this.clicked.emit(event);
    event.stopPropagation();
  }

  protected get classes(): string[] {
    const classes = [this.getButtonClass()];
    const buttonSizeClass = this.getButtonSizeClass();
    if (buttonSizeClass) classes.push(buttonSizeClass);
    if (this.disabled || this.isLoading) classes.push('disabled');
    if (this.isLoading) classes.push('loading');
    if (this.fullwidth) classes.push('w-100');
    if (this.color === 'success' && !this.outline) classes.push('text-white');
    return classes;
  }

  private getButtonSizeClass(): string | undefined {
    switch (this.size) {
      case 'xs':
        return 'btn-xs';
      case 'small':
        return 'btn-sm';
      case 'medium':
        return undefined;
      case 'large':
        return 'btn-lg';
    }
  }

  private getButtonClass(): string {
    if (this.variant === 'button') {
      return this.outline ? `btn-outline-${this.color}` : `btn-${this.color}`;
    } else {
      return `btn-link-${this.color}`;
    }
  }
}
